export const Image = ({ title, largeImage, smallImage }) => {
  return (
    <div className='portfolio-item'>
      <div className=''>
        {' '}
        <a
          href={largeImage} target="_blank"
          title={title}
          data-lightbox-gallery='gallery1'
        >
          
          <img
            src={smallImage}
            className='img-responsive'
            alt={title}
          />{' '}
<br></br>
          <div className='hover-text'>
            <h2>{title}</h2>
          </div>
        </a>{' '}
      </div>
    </div>
  )
}